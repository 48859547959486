$font-family: 'Matter SQ';
$font-location: '/assets/fonts/';

@font-face {
    font-weight: 300;
    font-family: $font-family;
    // font-style: normal;
    src: url('#{$font-location}MatterSQ-Light.woff2') format('woff2');
    src: url('#{$font-location}MatterSQ-Light.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-weight: 400;
    font-family: $font-family;
    // font-style: normal;
    src: url('#{$font-location}MatterSQ-Regular.woff2') format('woff2');
    src: url('#{$font-location}MatterSQ-Regular.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-weight: 500;
    font-family: $font-family;
    // font-style: normal;
    src: url('#{$font-location}MatterSQ-Medium.woff2') format('woff2');
    src: url('#{$font-location}MatterSQ-Medium.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-weight: 600;
    font-family: $font-family;
    // font-style: normal;
    src: url('#{$font-location}MatterSQ-SemiBold.woff2') format('woff2');
    src: url('#{$font-location}MatterSQ-SemiBold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-weight: 700;
    font-family: $font-family;
    // font-style: normal;
    src: url('#{$font-location}MatterSQ-Bold.woff2') format('woff2');
    src: url('#{$font-location}MatterSQ-Bold.woff') format('woff');
    font-display: swap;
}

@font-face {
    font-weight: 900;
    font-family: $font-family;
    // font-style: normal;
    src: url('#{$font-location}MatterSQ-Heavy.woff2') format('woff2');
    src: url('#{$font-location}MatterSQ-Heavy.woff') format('woff');
    font-display: swap;
}
