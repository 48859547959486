$font-family: 'Alternate Gothic';
$font-location: '/assets/fonts/';

@font-face {
    // font-weight: 100;
    font-family: $font-family;
    // font-style: normal;
    src: url('#{$font-location}Alternate Gothic No1 D Regular.woff2') format('woff2');
    src: url('#{$font-location}Alternate Gothic No1 D Regular.woff') format('woff');
    font-display: swap;
}
