@import './fonts';
@import './main';
@import './modal';
@import './placeholder';
@import './auth';


//custom CSS
.section-heading {
  font-family: var(--fontFamily);
  font-size: 1.3em;
  font-weight: bold;
  letter-spacing: normal;
  color: #373737;
}

app-footer {
  z-index: 9;
  position: relative;
}

.drop-modal-title {
  font-size: 1em;
  text-align: center;
  position: relative;
  width: 100%;
}

.df-toast-wrapper {
  li {
    list-style: none !important;
  }

  .text-bg-info {
    background-color: black !important;
    color: white !important;
  }

  .btn-close {
    color: white !important;
    background: url(/assets/images/close-white.svg) center center !important;
  }
}

.modal-fullscreen .modal-content {
  overflow-y: scroll;
}

/* Max width 767px */
@media only screen and (max-width : 767px) {
  .modal-header {
    position: fixed;
    top: 0;
    background-color: #fff;
    width: 100%;
    z-index: 9;
  }

  .modal-body {
    padding-bottom: 80px;
    padding-top: 80px;
  }

  .modal-footer {
    position: fixed;
    bottom: 0;
    background-color: #ffffff;
    width: 100%;
  }
}


//Floating form
.form-floating>.form-control.textarea {
  height: auto;
}

.form-floating .invalid-feedback {
  display: none;
}

.form-floating input.form-control.ng-invalid~.invalid-feedback,
.form-floating input.form-control:focus.ng-invalid~.invalid-feedback,
.form-floating input.form-control:not(:placeholder-shown).ng-invalid~.invalid-feedback,
.form-floating textarea.form-control.ng-invalid~.invalid-feedback,
.form-floating textarea.form-control:focus.ng-invalid~.invalid-feedback,
.form-floating textarea.form-control:not(:placeholder-shown).ng-invalid~.invalid-feedback,
.form-floating select.form-control.ng-invalid~.invalid-feedback,
.form-floating select.form-control:focus.ng-invalid~.invalid-feedback,
.form-floating select.form-control:not(:placeholder-shown).ng-invalid~.invalid-feedback,
.form-floating ngx-recaptcha2.ng-invalid~.invalid-feedback,
.form-floating ngx-recaptcha2:not(:placeholder-shown).ng-invalid~.invalid-feedback {
  display: block;
}

.filter-timing-slider-container .ngx-slider {
  .ngx-slider-bar {
    background-color: var(--grayColor);
  }

  .ngx-slider-pointer
  {
      background-color: var(--blackColor);
  }

  .ngx-slider-selection {
      background-color: var(--blackColor);
  }

  .ngx-slider-pointer.ngx-slider-active:after {
      background-color: var(--whiteColor);
  }
}