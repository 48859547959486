// .modal.fade .modal-dialog {
//   transform: translate3d(0, 100vh, 0);
// }

// .modal.in .modal-dialog {
//   transform: translate3d(0, 0, 0);
// }

/* Max width 767px */
@media only screen and (max-width : 767px) {
  .margined-modal .modal-dialog {
    margin: 1rem;
  }
}
.modal-dialog.modal-fullscreen {
  padding-top: 1rem;
  .modal-content {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }
}