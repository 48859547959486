$borderColor: #979797;
// custom
$primary: #0072CE;
$accent: #49beff;
$warning: #ffae1f;
$error: #fa896b;
$success: #13deb9;
$white: #ffffff;
$black: #2d2d2d;
$link: #2d2d2d;
$secondaryText: #9b9b9b;


.blank-layout-container {
  height: 100vh;
  display: flex;
  background-size: cover;
  align-items: center;
}

.auth-title {
  font-size: 36px;
  line-height: 44px;
}

.bg-gredient {
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    opacity: 0.3;
    background: radial-gradient(
        rgb(210, 241, 223),
        rgb(211, 215, 250),
        rgb(186, 216, 244)
      )
      0% 0% / 400% 400%;
    animation: 15s ease 0s infinite normal none running gradient;
    z-index: -1;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 50% 0%;
  }
}

.img-height {
  height: calc(100vh - 83px);
}

.custom-row {
  &.row {
    margin-right: -8px;
    margin-left: -8px;
    .col-2,
    .col-12 {
      padding-right: 8px;
      padding-left: 8px;
    }
  }
}

.or-border {
  position: relative;
  text-align: center;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100px;
    top: 50%;
    height: 1px;
    background: $borderColor;
  }
  &:after {
    content: "";
    position: absolute;
    right: 0;
    width: 100px;
    top: 50%;
    height: 1px;
    background: $borderColor;
  }
}

.boxed-auth {
  width: 100%;
  max-width: 480px;
  margin: 0 auto;
}

.max-width-form {
  max-width: 450px;
}

.mat-mdc-form-field.mat-form-field-invalid {
  .mdc-notched-outline__leading,
  .mdc-notched-outline__notch,
  .mdc-notched-outline__trailing {
    border-color: $error !important;
  }
}

.error-msg {
  position: absolute;
  top: -19px;
  right: 3px;
}

.master-login-image-bg {
  background-image: url("/assets/images/backgrounds/trainer.jpg");
  background-size: cover;
  background-position: center center;
  a {
    position: relative;
    top: 23em;
    left: 1em;
    height: 5em;
  }
}

.login-image-bg {
  background-image: url("/assets/images/backgrounds/facility.webp");
  background-size: cover;
  background-position: center center;
  a {
    position: relative;
    top: 4em;
  }
}